import { TABLA, ACCIONES_A_REALIZAR } from '@/utils/consts'
import { ReadFileBase64 } from '@/utils/files'
import { v4 as uuidv4 } from 'uuid'

export default {
  async insert (Vue, formData, idpreguntaChecklistOt, idtfichero, idtecnico, idparteTrabajo) {
    const idtanomaliaChecklistOt = uuidv4()
    await Vue.$offline.tanomaliaChecklistOt.insertSync({
      idtanomalia_checklist_ot: idtanomaliaChecklistOt,
      idpregunta_checklist_ot: idpreguntaChecklistOt,
      idtanomalia_checklist: formData.idtanomalia_checklist,
      idgrado_anomalia: formData.idgrado_anomalia,
      descripcion: formData.descripcion,
      descripcion_ampliada: formData.descripcion_ampliada,
      mejora: formData.mejora,
      subsanado: formData.subsanado,
      agregaradefinicioncheck: formData.agregaradefinicioncheck,
      notas_internas: formData.notas_internas,
      estado: 1,
    })
    for (const fieldname in formData) {
      if (fieldname.startsWith('idaccion_correctora_')) {
        const idaccionCorrectora = parseInt(fieldname.substring('idaccion_correctora_'.length))
        const accionCorrectora = await Vue.$offline.accionCorrectora.row(idaccionCorrectora)
        // tengo que buscar si ha marcado el check de realizar
        const fieldnameAccionesARealizar = `${ACCIONES_A_REALIZAR.fieldname}${idaccionCorrectora}`
        const AccionesARealizarValue = formData[fieldnameAccionesARealizar];
        if (AccionesARealizarValue === ACCIONES_A_REALIZAR.values.incluir || AccionesARealizarValue === ACCIONES_A_REALIZAR.values.incluir_y_realizar) {
          const realizarValue = AccionesARealizarValue === ACCIONES_A_REALIZAR.values.incluir_y_realizar
          const idaccionCorrectoraOt = uuidv4()
          await Vue.$offline.accionCorrectoraOt.insertSync({
            idaccion_correctora_ot: idaccionCorrectoraOt,
            idtanomalia_checklist_ot: idtanomaliaChecklistOt,
            idaccion_correctora: idaccionCorrectora,
            descripcion: accionCorrectora.accion_correctora.descripcion,
            descripcion_ampliada: accionCorrectora.accion_correctora.descripcion_ampliada,
            realizar: realizarValue,
            observaciones: accionCorrectora.accion_correctora.observaciones,
            estado: 1,
            idarticulo: accionCorrectora.accion_correctora.idarticulo,
            idmaccion: accionCorrectora.accion_correctora.idmaccion,
            unidades: accionCorrectora.accion_correctora.unidades,
          })
          if (realizarValue) {
            Vue.$offline.accionCorrectoraOt.marcarRealizada(idaccionCorrectoraOt, idparteTrabajo)
          }
        }
      }
    }
    // actualizar el grado de anomalía de la pregunta
    // al máximo orden de los grados de anomalía de sus anomalías
    await Vue.$offline.preguntaChecklistOt.updateMaxGradoSync(idpreguntaChecklistOt, idtecnico)
    for (let file of formData.files) {
      await Vue.$offline.fichero.insertSync({
        idfichero: uuidv4(),
        nombre_fichero: file.name,
        idtabla: TABLA.tanomalia_checklist_ot.idtabla,
        data: await ReadFileBase64(file),
        id: idtanomaliaChecklistOt,
        idtfichero,
        type: file.type,
      })
    }
    return idtanomaliaChecklistOt
  },
}
